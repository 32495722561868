import React, { Component, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import warning from 'warning'
import { css, cx } from 'emotion'
import ReactMarkdown from 'react-markdown'
import { throttle } from 'lodash'
import { getIsUnifiedShellEnabled } from '@wf-mfe/unified-shell-bootstrapper'

import {
  Dialog,
  addIframeClickListener,
  removeIframeClickListener,
  toast,
  A,
} from '@phoenix/all'
import {
  navStore,
  getQuicksilverPath,
  action_MINIX_CLOSE,
  action_MINIX_OPEN,
} from '@wf-mfe/navigation'
import { logRumEvent } from '@wf-mfe/logger'
import { useIsTreatmentEnabled } from '@wf-mfe/toggles'

import {
  Localization,
  sanitizeHtml,
} from './utilities/RedrockLocalizationProvider'
import { postMessage } from './postMessage'
import listBaseStyles from './styles/listBaseStyles'
import monolithBaseStyles from './styles/monolithBaseStyles'
import {
  ReferenceReplacementDialog,
  ExportPageDialog,
  FilterBuilderDialog,
  FilterViewGroupRemoveDialog,
  GroupingBuilderDialog,
  LucidSharingDialog,
  ViewBuilderDialog,
  baseDialogTilePaths,
  handleDialogMessage,
  handleDialogCloseMessage,
} from '@wf-mfe/dialogs'

//TODO emotion.next (v10?) is expected to support rendering a style tag to another
//document, if we ever upgrade we can get rid of this
//https://github.com/emotion-js/emotion/issues/560
import './utilities/prefixfree'

const TILE_TIMINGS = {
  frameRender: 'iframe-rendered',
  frameLoad: 'iframe-loaded',
  tileReady: 'iframe-tile-ready',
  tileRequest: 'iframe-tile-request',
  listReady: 'iframe-list-ready',
}

// gathering some metrcis for ELT
const eventCaptured = {}
const reportTileTiming = (timingName, props) => {
  if (eventCaptured[timingName]) {
    return
  }
  eventCaptured[timingName] = true

  logRumEvent(`qs-${timingName}`, {
    tile: props?.tile || props?.parameters?.content,
    [timingName]: performance.now(),
  })
}

let replaceCount = 0
window.addEventListener('popstate', (e) => {
  const type = e.singleSpaTrigger || 'pushState'
  // our secondary navigation does an initial replace when it doesn't need to
  // after the initial load using the secondary nav is also a replace
  // we don't want to capture after the frist one since you could sit on an
  // initial page like updates for an hour and then go to a shim
  if (type === 'replaceState') {
    replaceCount++
  }

  if (type === 'pushState' || replaceCount > 1) {
    Object.values(TILE_TIMINGS).forEach(
      (value) => (eventCaptured[value] = true)
    )
  }
})

const browserSupportsResizeObserver = typeof ResizeObserver === 'function'

export function KaminoShim({ history, location, ...rest }) {
  const locationV6 = useLocation()
  const navigate = useNavigate()

  warning(!history, 'KaminoShim no longer accepts the history prop')
  warning(!location, 'KaminoShim no longer accepts the location prop')

  return <KaminoShimClass {...rest} navigate={navigate} location={locationV6} />
}

class KaminoShimClass extends Component {
  //static contextType = SplitContext;

  props: {
    navigate: Object,
    location: Object,

    baseStyleInjection?: boolean,
    dialogs?: Object<String, Function>,
    dialogTilePaths?: Object,
    eventBus?: Object<String, Function>,
    /** whether the content inside the shim extends to the edges and also indents first list column by 20px */
    fullBleed?: boolean,
    height?: Number,
    /** If this prop is present, then the shared kamino dialogs will be added automatically.  */
    isList?: boolean,
    /** For pages such as public reports, if present the TileLoader in the shim will use /publicTile vs /tile */
    isPublic?: boolean,
    kaminoDialogs?: Object<String, Object>,
    legacyScripts?: String | Array<String>,
    /* legacy scripts async mode, see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script#attr-async} */
    legacyScriptsAsync?: boolean,
    legacyStyles?: boolean,
    /** deprecated prop */
    legacyStylesheet?: String | Array<String>,
    legacyStylesheets?: Array<String>,
    onMessage?: Function,
    obj?: Object,
    parameters?: Object,
    selfScrolling?: boolean,
    src?: String,
    styles?: String,
    tile?: String,
    urlAppend?: String,
    width?: Number,
    frameID?: String,
  }

  static defaultProps = {
    baseStyleInjection: true,
    dialogs: {},
    kaminoDialogs: {},
  }

  constructor(props) {
    super(props)

    this.pathname = props.location?.pathname

    //the iframe
    this.frame = React.createRef()
    //the div around the iframe
    this.frameContainer = React.createRef()
    this.mounted = false
    //used for best resize experience, Chrome only
    this.ResizeObserver = undefined
    //new lists and a few other pages have internal scroll
    //this is to set the container height to the minimum
    //for no QS page scroll and iframe-only scroll
    this.initialSize = undefined
    //for non-Chrome we check size a few times with timeouts
    this.contentSyncTimeout = undefined

    this.state = {
      confirmDialog: null,
      dialog: null,
      dialogs: {},
      frameID: '',
      kaminoDialog: null,
      kaminoDialogs: {},
      kaminoNotify: null,
      fauxDialog: false,
      hasError: false,
    }
  }

  componentDidCatch(error, info) {
    console.log(error)
    console.log(info)
  }

  componentDidUpdate() {
    this.setTileProperties()
  }

  componentDidMount() {
    this.mounted = true
    if (this.props.isList !== true) {
      window.addEventListener('resize', this.setInitialSize)
    }
    window.addEventListener('popstate', this.resetDialogState)
    document.body.addEventListener('click', this.sendClickToIframe)
    document.body.addEventListener('keydown', this.sendKeyCombinationToIframe)
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.props.isList !== true) {
      window.removeEventListener('resize', this.setInitialSize)
    }
    window.removeEventListener('popstate', this.resetDialogState)
    document.body.removeEventListener('click', this.sendClickToIframe)
    document.body.removeEventListener(
      'keydown',
      this.sendKeyCombinationToIframe
    )

    removeIframeClickListener(this.frame.current)

    typeof window !== 'undefined' &&
      window.removeEventListener('message', this.listenForMessage, false)

    if (this.ResizeObserver) {
      this.ResizeObserver.disconnect()
    }
  }

  static getDerivedStateFromProps(props) {
    let dialogs =
      typeof props.dialogs === 'function' ? props.dialogs(props) : props.dialogs
    let kaminoDialogs =
      typeof props.kaminoDialogs === 'function'
        ? props.kaminoDialogs(props)
        : props.kaminoDialogs

    if (props.isList) {
      dialogs = {
        ...dialogs,
        ...ExportPageDialog(props),
        ...FilterBuilderDialog(props),
        ...FilterViewGroupRemoveDialog(props),
        ...GroupingBuilderDialog(props),
        ...LucidSharingDialog(props),
        ...ViewBuilderDialog(props),
      }
      kaminoDialogs = {
        ...kaminoDialogs,
        ...ReferenceReplacementDialog,
      }
    }

    return {
      dialogs,
      frameID:
        props.frameID ||
        props.tile ||
        (props.parameters ? props.parameters.content : ''),
      kaminoDialogs,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  getFrameSrc = () => {
    const { src, urlAppend } = this.props

    if (src) return src

    let pathQuery = this.pathname ? `&qsPath=${this.pathname}` : ''
    let appendQuery = urlAppend ? `&${urlAppend}` : ''
    return `/qstile?qs=true${pathQuery}${appendQuery}`
  }

  frameReady = false
  nextPropsQueued = null

  render() {
    if (this.state.hasError) {
      return <div>Error loading secondary content...</div>
    }

    return (
      // TODO: Should the click handler on this div be handled a little differently?
      // For example, should it have a role and a keypress handler too?
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        ref={this.frameContainer}
        className={cx(
          growTheIframe,
          { [fauxDialogBoxStyles]: this.state.fauxDialog },
          {
            [fullScreenShim]:
              this.props.height === undefined && this.props.width === undefined,
          },
          {
            [fullWidthShim]:
              this.props.height !== undefined && this.props.width === undefined,
          }
        )}
        data-testid="frame-container"
        onClick={this.handleContainerClick}
      >
        <ShimTracking
          shim={this.props?.tile || this.props?.parameters?.content}
        />
        <Frame
          onMount={this.handleFrameMount}
          id={this.state.frameID}
          forwardRef={this.frame}
          src={this.getFrameSrc()}
          style={
            this.props.height || this.props.width
              ? { height: this.props.height, width: this.props.width }
              : {}
          }
        />
        {this.state.kaminoDialog && this.state.kaminoDialog()}
        {this.state.confirmDialog && this.state.confirmDialog()}
        {this.state.kaminoNotify && this.state.kaminoNotify()}
        {this.state.dialog ? (
          React.isValidElement(this.state.dialog) ? (
            this.state.dialog
          ) : (
            <Dialog {...this.state.dialog} />
          )
        ) : null}
        {this.state.fauxDialog && <FauxDialogStylingSideEffects />}
      </div>
    )
  }

  getLayoutContainer = () =>
    this.frame.current.contentDocument.getElementById('layout-container')

  frameExistsAndHasLoaded = () => this.frame.current?.contentDocument?.body

  setInitialSize = () => {
    const height = this.frameContainer.current.style.height
    this.frameContainer.current.style.height = 'auto'
    this.initialSize = this.frameContainer.current.getBoundingClientRect()
    this.frameContainer.current.style.height = height
  }

  resetDialogState = () => {
    // Clear out any open dialogs when we change pages.
    this.setState({
      dialog: null,
      kaminoDialog: null,
    })
  }

  sendClickToIframe = (event) => {
    /*
      We should make sure that we do not send the click event to the iframe if the iframe is in the dialog and the submit button is clicked.
      Otherwise, all selected items in the iframe are going to be deselected.
    */
    const isConfirmButtonClicked =
      event.target.tagName === 'BUTTON' &&
      event.target.getAttribute('data-testid') ===
        'confirm-button-confirm-dialog'
    const containerContainsTarget = (container) =>
      container.contains(event.target)

    // If MiniX is open, then clicking inside it (or something it opens) should not deselect the selected list item.
    const miniX = Array.from(
      document.querySelectorAll('[data-testid="minix-container"]')
    ).filter((node) => node.offsetHeight > 0)
    const tooltipContainer = document.querySelector('#tooltip-container')
    const dialogContainer = document.querySelector('#dialog-container')
    const optionsContainer = document.querySelector('#options-container')
    const isMiniXOpen =
      miniX.length &&
      [...miniX, tooltipContainer, dialogContainer, optionsContainer].some(
        containerContainsTarget
      )

    const iframeIsHidden = this.frame.current?.offsetHeight === 0

    if (
      !iframeIsHidden &&
      !isMiniXOpen &&
      !isConfirmButtonClicked &&
      !containerContainsTarget(dialogContainer)
    ) {
      postMessage(
        {
          type: 'quicksilverClick',
        },
        this.frame.current
      )
    }
  }

  sendKeyCombinationToIframe = (event) => {
    const { altKey, ctrlKey, keyCode, key, code } = event
    if (altKey || ctrlKey) {
      postMessage(
        {
          type: 'quicksilverKeydown',
          event: { altKey, ctrlKey, keyCode, key, code },
        },
        this.frame.current
      )
    }
  }

  isNewList = () => hasReactList(this.frame.current.contentDocument)

  isSelfScrolling = () => {
    return this.isNewList() || this.props.selfScrolling
  }

  setHeight = (contentRect) => {
    //10000 is an attempt to be larger than any app should be initially so
    //the app can tell the iframe how big it is really, this could probably
    //revisited and tested later, but things are working now, in any case
    //when the first tile loads we set initial size for real
    const initialSize = this.initialSize || { height: 10000 }

    this.frameContainer.current.style.height = this.isSelfScrolling()
      ? 'auto'
      : Math.max(contentRect.height, initialSize.height) + 'px'
  }

  /*
  isFeatureEnabled(featureName) {
    const treatments = this.context.treatments || {};
    const feature = treatments[featureName];
    const treatment = feature && feature.treatment;
    return treatment === 'on';
  }
  */

  syncFrameSize = () => {
    if (this.frameExistsAndHasLoaded()) {
      if (this.props.height === undefined) {
        const layoutContainerElement = this.getLayoutContainer()

        if (this.props.isList !== true) {
          this.setHeight(layoutContainerElement.getBoundingClientRect())
        }

        if (!browserSupportsResizeObserver) {
          let timeouts = [0, 1000, 3000, 5000]

          const startTimer = (t) => {
            if (this.mounted) {
              if (this.props.isList !== true) {
                this.setHeight(layoutContainerElement.getBoundingClientRect())
              }

              if (timeouts.length) {
                return setTimeout(() => {
                  startTimer(timeouts.shift())
                }, t)
              }
            }
          }

          clearTimeout(this.contentSyncTimeout)
          this.contentSyncTimeout = startTimer(timeouts.shift())
        }
      } else {
        this.frameContainer.current.style.height = this.props.height + 'px'
      }

      if (this.props.width) {
        this.frame.current.style.minWidth = this.props.width + 'px'
      }
    }
  }

  handleFrameMount = () => {
    window.addEventListener('message', this.listenForMessage, false)

    const onFrameLoad = () => {
      reportTileTiming(TILE_TIMINGS.frameLoad, this.props)
      this.frame.current.removeEventListener('load', onFrameLoad)

      this.observeResize()

      this.frameReady = true

      if (this.nextPropsQueued) {
        const nextProps = { ...this.nextPropsQueued }
        this.nextPropsQueued = null
        this.loadTile(nextProps)
      } else {
        this.loadTile()
      }

      addIframeClickListener(this.frame.current)
    }

    this.frame.current?.addEventListener?.('load', onFrameLoad)
  }

  observeResize = () => {
    this.ResizeObserver?.disconnect?.()

    const heightIsStatic = this.props.height != null

    //new lists will just keep growing
    const isList = this.isNewList() || this.props.isList

    if (!isList && !heightIsStatic && browserSupportsResizeObserver) {
      const handleResize = throttle(([layoutContainer]) => {
        this.setHeight(layoutContainer.contentRect)
      }, 500)

      this.ResizeObserver = new ResizeObserver(handleResize)
      this.ResizeObserver.observe(this.getLayoutContainer())
    }
  }

  //TODO: lets see if we can extract this logic somewhat too, I think we could make
  // a nice api around this that developers could consume
  getStylesFromProps = (props) => {
    let style = document.createElement('style')
    style.type = 'text/css'
    const styles =
      (props.baseStyleInjection ? monolithBaseStyles : '') +
      (props.styles || '') +
      (props.width
        ? `
        #layout-container, #layout-content, .page-content-width {
          min-width: ${props.width}px!important;
          outline: none;
        }`
        : '') +
      (props.fullBleed || props.isList ? listBaseStyles : '') +
      `
        #layout-container {
          box-sizing: border-box;
          padding: 0;
        }

        .new-footer {
          display: none;
        }

        #sandbox-banner {
          display: none;
        }

        .targetLeft {
          z-index: 1;
        }
      `

    if (style.styleSheet) {
      style.styleSheet.cssText = styles
    } else {
      style.appendChild(document.createTextNode(styles))
    }

    //StyleFix adds vendor prefixes (mainly for IE11)
    if (window.StyleFix) {
      window.StyleFix.styleElement(style)
    }

    return style.innerText
  }

  getStylesheetsFromProps = (nextProps) => {
    const props = nextProps || this.props

    let stylesheets = []
    if (props.legacyStylesheet) {
      warning(
        !props.legacyStylesheet,
        'legacyStylesheet is deprecated as a prop, use legacyStylesheets instead (and Marvel is sorry)'
      )

      stylesheets = stylesheets.concat(props.legacyStylesheet)
    }

    if (props.legacyStylesheets) {
      stylesheets = stylesheets.concat(props.legacyStylesheets)
    }

    return stylesheets
  }

  loadTile = (nextProps) => {
    const props = nextProps || this.props

    const addListPerfMeasurement = props.isList
    reportTileTiming(TILE_TIMINGS.tileRequest, props)

    if (addListPerfMeasurement) {
      postMessage(
        {
          type: 'addEvent',
          eventName: 'listTableReady',
        },
        this.frame.current
      )
    }

    const { location } = this.frame.current.contentDocument
    if (location.pathname === '/login') {
      window.location = `${location.origin}${
        location.pathname
      }?nextURL=${encodeURIComponent(window.location.pathname)}`
      return
    }

    postMessage(
      {
        type: 'preventDefaultDialog',
        tiles: Object.keys(baseDialogTilePaths)
          .concat(Object.keys(props.dialogTilePaths || {}))
          .concat(Object.keys(this.state.dialogs || {}))
          .concat(Object.keys(this.state.kaminoDialogs)),
      },
      this.frame.current
    )

    if (props.parameters) {
      if (props.isList !== true) {
        if (this.props.height === undefined) {
          if (this.initialSize === undefined) {
            this.setInitialSize()
          }

          this.frameContainer.current.style.height = '1px'
        }
      }

      postMessage(
        {
          type: 'loadTile',
          detailObject: props.obj || {},
          legacyStyles: props.legacyStyles,
          parameters: props.parameters,
          styles: this.getStylesFromProps(props),
          stylesheets: this.getStylesheetsFromProps(props),
          scripts: props.legacyScripts,
          asyncScripts: props.legacyScriptsAsync,
          isPublic: props.isPublic,
          // TODO total hack due to bad closure in RR
          eventName: addListPerfMeasurement ? 'listTableReady' : undefined,
        },
        this.frame.current
      )
    } else {
      postMessage(
        {
          type: 'unloadTile',
        },
        this.frame.current
      )
    }

    if (props.eventBus) {
      Object.keys(props.eventBus).forEach((eventName) =>
        postMessage(
          {
            type: 'addEvent',
            eventName,
          },
          this.frame.current
        )
      )
    }
  }

  setTileProperties = () => {
    if (this.frameReady) {
      this.loadTile(this.props)
    } else {
      this.nextPropsQueued = this.props
    }
  }

  listenForMessage = (msg) => {
    const { data, origin, source } = msg

    const onSameOrigin = origin === window.location.origin
    const isMessageFromQuicksilver = data?.source?.startsWith?.('qs')
    const isSourceFromIFrame = source === this.frame.current?.contentWindow

    const shouldHandleMessage =
      onSameOrigin && isMessageFromQuicksilver && isSourceFromIFrame

    if (shouldHandleMessage) {
      if (this.props.onMessage) {
        const boundPostMessage = (msg) =>
          postMessage(msg, this.frame.current.id)
        const reloadParentTile = () => boundPostMessage({ type: 'reloadTile' })

        this.props.onMessage(data, {
          postMessage: boundPostMessage,
          reloadTile: reloadParentTile,
        })
      }

      if (data.source === 'qstileready') {
        reportTileTiming(TILE_TIMINGS.tileReady, this.props)
        return this.syncFrameSize()
      }

      if (data.source === 'qstileresize') {
        if (data.height) {
          this.frameContainer.current.style.height = data.height + 'px'
        }
      }

      handleMessage(
        { data },
        {
          props: this.props,
          state: this.state,
          setState: (s) => this.setState(s),
          origin,
        }
      )
    }
  }

  handleContainerClick = () => {
    if (this.state.fauxDialog) {
      postMessage({ type: 'fakeScrimClick' }, this.frame.current)
      this.setState({ fauxDialog: false })
    }
  }
}

function hasReactList(element) {
  return element.querySelector('div[data-name="react-datatable"]')
}

function ShimTracking({ shim }) {
  const trackShimLoads = useIsTreatmentEnabled('track-shim-loads')

  useEffect(() => {
    trackShimLoads && logRumEvent(`qs-kamino-shim`, { shim })
  }, [shim, trackShimLoads])

  return null
}

class Frame extends Component {
  props: {
    className: String,
    src: String,
    onMount: Function,
    id: String,
    forwardRef: Object,
    style?: Object,
    iframeTitle?: String,
  }

  shouldComponentUpdate(nextProps) {
    return this.props.src !== nextProps.src
  }

  componentDidMount() {
    this.props.onMount()
  }

  render() {
    reportTileTiming(TILE_TIMINGS.frameRender, this.props)

    return (
      <iframe
        ref={this.props.forwardRef}
        id={this.props.id}
        data-test-id="kamino-shim"
        data-testid="kamino-shim"
        src={this.props.src}
        className={this.props.className}
        style={this.props.style}
        title={this.props.iframeTitle || 'kamino-shim'}
      />
    )
  }
}

function FauxDialogStylingSideEffects() {
  useEffect(() => {
    const secondaryNavDiv = document.querySelector('#secondary-nav')
    const sidebarDiv = document.querySelector('#page-sidebar')
    const contentDiv = document.querySelector('#page-content')

    if (secondaryNavDiv) {
      secondaryNavDiv.classList.add('relative')
      secondaryNavDiv.style.zIndex = 0
    }

    if (sidebarDiv) {
      sidebarDiv.style.zIndex = 0
    }

    if (contentDiv) {
      contentDiv.classList.add('relative')
      contentDiv.style.zIndex = 1
    }

    return () => {
      if (secondaryNavDiv) {
        secondaryNavDiv.classList.remove('relative')
        secondaryNavDiv.style.zIndex = ''
      }

      if (sidebarDiv) {
        sidebarDiv.style.zIndex = ''
      }

      if (contentDiv) {
        contentDiv.classList.remove('relative')
        contentDiv.style.zIndex = ''
      }
    }
  }, [])

  return null
}

const ieHeightFix = `
  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    iframe {
      height: 100%;
    }
  }
`

const fullScreenShim = css(`
  ${ieHeightFix};
  iframe {
    width: 100%;
    border: 0;
    position: relative;
    flex-grow: 1;
  }
`)

const fullWidthShim = css(`
  iframe {
    width: 100%;
  }
`)

const growTheIframe = css(`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
`)

const fauxDialogBoxStyles = css(`
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #34373d;
    opacity: 0.5;
    z-index: 2;
  }

  iframe {
    z-index: 2;
  }
`)

export function handleMessage(msg, kaminoShimProperties) {
  const { data } = msg

  if (data.source === 'qsnav' && data.href !== 'javascript://') {
    return handleNavigationMessage.apply(null, arguments)
  } else if (data.source === 'qsALT_ON') {
    navStore.dispatch({ type: 'ALT_ON' })
  } else if (data.source === 'qsALT_OFF') {
    navStore.dispatch({ type: 'ALT_OFF' })
  } else if (data.source === 'qsLoginAs') {
    // We cannot do history.push here, because it makes Quicksilver to go to home, which loads the data as admin's context and mixes everything//
    // We need to make sure that the page is just reloaded to read the new value of quicksilver cookie.
    window.location = '/'
  } else if (data.source === 'qsfakekaminomodalbg') {
    kaminoShimProperties.setState({ fauxDialog: true })
  } else if (data.source === 'qsfakekaminomodalbg-hide') {
    kaminoShimProperties.setState({ fauxDialog: false })
  } else if (data.source === 'qsdialog') {
    return handleDialogMessage.apply(null, arguments)
  } else if (data.source === 'qsdialog-close') {
    return handleDialogCloseMessage.apply(null, arguments)
  } else if (data.source === 'qsnotification') {
    return handleNotificationMessage.apply(null, arguments)
  } else if (data.source === 'qsMinixOpen') {
    return navStore.dispatch(action_MINIX_OPEN(data.data))
  } else if (data.source === 'qsMinixClose') {
    return navStore.dispatch(action_MINIX_CLOSE())
  } else if (data.source === 'qseventbus') {
    const { eventBus = {} } = kaminoShimProperties.props

    if (data.eventName === 'listTableReady') {
      reportTileTiming(TILE_TIMINGS.listReady, kaminoShimProperties.props)

      if (eventBus['listTableReady'] === undefined) {
        return
      }
    }

    warning(
      eventBus[data.eventName],
      `eventName ${data.eventName} not handled on eventBus prop`
    )
    return eventBus[data.eventName]()
  } else if (data.source === 'qsNewProofComplete') {
    return reloadDocumentTileFolders()
  } else if (data.source === 'qsTileReloadByName') {
    return reloadTileByName(data.data)
  } else if (data.source === 'qsRedirect') {
    return kaminoShimProperties.props.navigate(data.data, { replace: true })
  }
}

export const TOAST_SUCCESS = 'success'
export const TOAST_ERROR = 'error'
export const TOAST_WARNING = 'warning'
export const TOAST_INFO = 'info'

const checkToastNotificationType = (selectedType, type) =>
  selectedType &&
  selectedType.split(' ').some((className) => className === type)

const handleNotificationMessage = ({ data = {} }) => {
  let ToastType = data.type

  if (checkToastNotificationType(ToastType, TOAST_SUCCESS)) {
    ToastType = TOAST_SUCCESS
  } else if (checkToastNotificationType(ToastType, TOAST_ERROR)) {
    ToastType = TOAST_ERROR
  } else if (checkToastNotificationType(ToastType, TOAST_WARNING)) {
    ToastType = TOAST_WARNING
  } else if (checkToastNotificationType(ToastType, TOAST_INFO)) {
    ToastType = TOAST_INFO
  } else {
    return
  }

  if (data.messageKey) {
    data.message = (
      <Localization
        asHtml
        messageKey={data.messageKey}
        args={data.messageArgs}
      />
    )
  } else if (data.message) {
    data.message = (
      <ReactMarkdown
        components={{
          p: 'span',
          // eslint-disable-next-line no-unused-vars
          a: ({ node, ...props }) => {
            return <A target="_blank" {...props} />
          },
        }}
        escapeHtml={false}
        children={sanitizeHtml(data.message)}
      />
    )
  }
  setTimeout(() => toast[ToastType](data.message), data.delay || 0)
}

const handleNavigationMessage = ({ data }, kaminoShimProperties) => {
  const { entries } = navStore.getState()

  if (
    data.historyTest &&
    entries.length > 1 &&
    data.historyTest.test(entries[entries.length - 2])
  ) {
    if (data.historyAction) {
      switch (data.historyAction) {
        case 'GO_BACK':
          return kaminoShimProperties.props.navigate(-1)
        default:
          warning(
            data.historyAction,
            'historyAction should be one of the following: GO_BACK'
          )
      }
    }
  }

  if (data.href === '/logout') {
    if (getIsUnifiedShellEnabled()) {
      return System.import('@adobe/exc-app/user')
        .then((module) => module.default)
        .then((user) => {
          user.authExpired()
        })
    }
  }

  if (
    data.target === '_blank' ||
    data.href.match(/\/download\//) ||
    data.href.match(
      /(http|https|ftp|ftps):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/
    )
  ) {
    window.open(data.href, '_blank')
  } else {
    let newPath = getQuicksilverPath(data.href)

    // If we are passed a login page, then just set the window location.
    // Otherwise, use the browser history as usual.
    if (newPath.indexOf('/login') !== -1) {
      window.location = '/login'
    } else {
      kaminoShimProperties.props.navigate(
        getPathRelativeToCurrentLocation(
          newPath,
          kaminoShimProperties.props.location
        )
      )
    }
  }
}

const ABSOLUTE_OR_HASH_OR_QUERY_PARAM_PATH_REGEX = /^[/#?]/

function getPathRelativeToCurrentLocation(newPath, location) {
  if (ABSOLUTE_OR_HASH_OR_QUERY_PARAM_PATH_REGEX.test(newPath)) {
    return newPath
  }

  const baseLocationArray = location.pathname.split('/').filter(Boolean)
  const newPathArray = newPath.split('/').filter(Boolean)

  const sameBasePage = baseLocationArray[0] === newPathArray[0]

  if (sameBasePage) {
    return `/${newPath}`
  }

  baseLocationArray.pop()
  const baseLocationPath = baseLocationArray.join('/')

  return `/${baseLocationPath}/${newPath}`
}

const shimSelector = 'iframe[data-test-id="kamino-shim"]'

const reloadDocumentTileFolders = () => {
  const shims = document.querySelectorAll(shimSelector)
  for (var i = 0; i < shims.length; i++) {
    postMessage({ type: 'reload-folder' }, shims[i])
  }
}

const reloadTileByName = ({ tileName }) => {
  if (tileName) {
    const shims = document.querySelectorAll(shimSelector)
    for (var i = 0; i < shims.length; i++) {
      postMessage({ type: 'reloadTileByName', tileName }, shims[i])
    }
  }
}
