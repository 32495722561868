import { buttonStyles, injectedStyles } from '@phoenix/all'

export default `
  html body {
    overflow: hidden !important;
  }

  .TabPanel > nav {
    display: none;
  }

  .page-content-width {
    padding: 0 !important;
  }

  #layout-content {
    max-width: none;
    min-height: calc(100vh - 2px);
  }

  #layout-main {
    padding-bottom: 0 !important;
    min-height: calc(100vh - 2px);
  }

  div[data-name="react-datatable"] div[data-wf-reactroot] > div {
    height: calc(100vh - 49px) !important;
  }

  #document-central-base {
    min-height: 100vh !important;
  }

  .hidden {
    display: none;
  }

  ${buttonStyles()}

  form.editing .edit-link.btn {
    display: none !important;
  }

  ${injectedStyles()}
`
