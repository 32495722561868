import React, { Suspense } from 'react'
import { Router } from 'react-router-dom'
import {
  CompatRouter,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom-v5-compat'

import { PageContentSlot } from '@wf-mfe/layout'
import { history } from '@wf-mfe/navigation'
import { MFELoggerAndReactErrorBoundary } from '@wf-mfe/logger'

import { KaminoShimCacheSystem } from './KaminoShimCacheSystem.js'
import { getBasePathnameFromPathname } from './utilities.js'

const MFE_NAME = '@wf-mfe/kamino'

export const KaminoShimContainer = () => {
  return (
    <PageContentSlot mfeName={MFE_NAME}>
      <Suspense fallback="">
        <Router history={history}>
          <CompatRouter>
            <Routes>
              <Route path="/*" element={<KacheSystemReRenderOnBasePathChange />} />
            </Routes>
          </CompatRouter>
        </Router>
      </Suspense>
    </PageContentSlot>
  )
}

const { ErrorBoundary } = MFELoggerAndReactErrorBoundary({ name: MFE_NAME })

function KacheSystemReRenderOnBasePathChange() {
  const { pathname } = useLocation()
  const basePathname = getBasePathnameFromPathname(pathname)
  return (
    <ErrorBoundary>
      <KaminoShimCacheSystem key={basePathname} basePathname={basePathname} />
    </ErrorBoundary>
  )
}
